<template>
    <div class="select">
        <div class="select-content">
            <div class="level">
                <span class="level-name">赛事：</span>
                <div class="level-content"
                     v-for="(option, index) in matchList"
                     :key="index"
                     :class="{customSelection: selectedMatchIndex === index }"
                     @click="selectMatch(option.name,index)">
                    <div class="level-sub-content">
                        <a>
                            {{option.label}}
                        </a>
                    </div>
                </div>

            </div>
            <div class="level">
                <span class="level-name">语言/分类：</span>
                <div class="level-content" v-for="(langOption, index) in level2Options" :key="index"
                     :class="{customSelection: selectedLangIndex === index }"
                     @click="selectLang(langOption.name,index)">
                    <div class="level-sub-content">
                        <a>
                            {{langOption.label}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="level">
                <span class="level-name">等级：</span>
                <div class="level-content" v-for="(level, index) in level3Options" :key="index"
                     :class="{customSelection: selectedLevelIndex === index }"
                     @click="selectLevel(level,index)">
                    <div class="level-sub-content">
                        <a>
                            {{level}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="level">
                <span class="level-name">更多：</span>
                <div class="level-content">
                    <div class="level-sub-content more">

                        <a :class="{ customSelection2: isActivePaperTypeFree }"
                           @click="selectPaperType(0)"
                           v-if="this.$route.path ==='/testPaperCenter'">
                            免费
                        </a>
                        <a :class="{ customSelection2: isActivePaperTypeVip}"
                           @click="selectPaperType(1)"
                           v-if="this.$route.path ==='/testPaperCenter'"
                           style="margin-right: 15px">
                            VIP
                        </a>

                        <el-date-picker
                                class="custom-date-picker"
                                v-if="this.$route.path !=='/wrongBook' "
                                v-model="paperMonth"
                                type="month"
                                value-format="yyyyMM"
                                @change="selectMonth"
                                placeholder="选择试卷月份">
                        </el-date-picker>
                        <el-select v-model="selectedQuestionType" class="custom-date-picker" clearable
                                   style="width:110px"
                                   v-if="this.$route.path ==='/questionBank' || this.$route.path ==='/wrongBook' "
                                   @change="selectByQuestionType"
                                   placeholder="选择题型">
                            <el-option
                                    v-for="item in questionType"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>

                        <el-date-picker
                                class="custom-date-picker"
                                style="margin-left:8px"
                                v-if="this.$route.path ==='/examinationRecord' || this.$route.path ==='/wrongBook' "
                                v-model="submitDate"
                                type="date"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @change="selectDate"
                                placeholder="选择答题时间">
                        </el-date-picker>

                        <el-input
                                class="paper-select"
                                placeholder="请输入搜索内容"
                                prefix-icon="el-icon-search"
                                clearable
                                v-model="selectContext"
                                @input="handleInput"
                                @keyup.enter="handleInput">
                        </el-input>


                        <el-button type="text" style="padding-left:30px" @click="resetSelect" class="el-icon-refresh">
                            重置
                        </el-button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "mainSelect",
        props: ['matchAndLang', 'selectPaperNameByHome'],
        data() {
            return {
                selectContext: '',
                paperYear: '',
                paperMonth: '',
                submitDate: '',
                selectedMatch: "ALL",
                selectedLang: "ALL",
                selectedLevel: "ALL",
                selectedMatchIndex: 0,
                selectedLangIndex: 0,
                selectedLevelIndex: 0,
                vipAnswer: null,
                isActivePaperTypeFree: false,
                isActivePaperTypeVip: false,
                matchList: [
                    {index: 0, "label": "全部", "name": "ALL", "full_name": ""},
                    {index: 1, "label": "电子学会", "name": "QCEIT", "full_name": "中国电子学会青少年等级考试"},
                    {index: 2, "label": "CCF GESP", "name": "CCF_GESP", "full_name": "GESP CCF编程能力等级认证"},
                    {index: 3, "label": "信息学奥赛", "name": "NOI", "full_name": "全国青少年信息学奥林匹克竞赛"},
                    {index: 4, "label": "蓝桥杯", "name": "LANQIAO", "full_name": "蓝桥杯全国软件和信息技术专业人才大赛"},
                    {index: 5, "label": "NOC", "name": "NOC", "NOC": "全国中小学信息技术创新与实践大赛"},
                    {index: 6, "label": "科技素养/计算思维", "name": "SYSW", "full_name": "全国青少年信息素养大赛"},
                    {index: 8, "label": "各校卷题", "name": "SCHOOL", "full_name": ""},
                    {index: 7, "label": "其他", "name": "OTHER", "full_name": ""}
                ],
                langList: [],
                levelList: [],
                langOptionsMap: {
                    "ALL": [
                        {"label": "全部", "name": "ALL"},
                        {"label": "图形化Scratch", "name": "Scratch"},
                        {"label": "C/C++", "name": "C"},
                        {"label": "Python", "name": "Python"},
                        {"label": "机器人技术", "name": "ROBOT"}
                    ],
                    "QCEIT": [
                        {"label": "全部", "name": "ALL"},
                        {"label": "图形化", "name": "Scratch"},
                        {"label": "C语言", "name": "C"},
                        {"label": "Python", "name": "Python"},
                        {"label": "机器人技术", "name": "ROBOT"}
                    ],
                    "CCF_GESP": [
                        {"label": "全部", "name": "ALL"},
                        {"label": "图形化", "name": "Scratch"},
                        {"label": "C/C++", "name": "C"},
                        {"label": "Python", "name": "Python"}
                    ],
                    "NOI": [
                        {"label": "全部", "name": "ALL"},
                        {"label": "专项训练", "name": "ZXXL"},
                        {"label": "NOI", "name": "NOI"},
                        {"label": "NOIP", "name": "NOIP"},
                        {"label": "CSP-J", "name": "CSP-J"},
                        {"label": "CSP-S", "name": "CSP-S"}
                    ],
                    "LANQIAO": [
                        {"label": "全部", "name": "ALL"},
                        {"name": "LANQIAO_Scratch", "label": "Scratch"},
                        {"name": "LANQIAO_C", "label": "C/C++"},
                        {"name": "LANQIAO_Python", "label": "Python"},
                        {"name": "SY", "label": "科技素养"}
                    ],
                    "NOC": [
                        {"label": "全部", "name": "ALL"},
                        {"label": "Scratch", "name": "Scratch"},
                        {"label": "C/C++", "name": "C"},
                        {"label": "Python", "name": "Python"}
                    ],
                    "SYSW": [
                        {"label": "全部", "name": "ALL"},
                        {"label": "科技素养", "name": "SY"},
                        {"label": "计算思维", "name": "SW"}
                    ],
                    "SCHOOL": [
                        {"label": "全部", "name": "ALL"},
                        {"label": "Scratch", "name": "Scratch"},
                        {"label": "C/C++", "name": "C"},
                        {"label": "Python", "name": "Python"},
                        {"label": "机器人技术", "name": "ROBOT"},
                        {"label": "其他", "name": "SCHOOL_OTHER"},
                    ],
                    "OTHER": [
                        {"label": "全部", "name": "ALL"},
                        {"label": "算法竞赛", "name": "SF"}
                    ]
                },
                levelOptionsMap: {
                    "ALL": ["全部", "一级", "二级", "三级", "四级", "五级", "六级", "七级", "八级", "九级", "十级", "练习", "其他"],
                    "Scratch": ["全部", "一级", "二级", "三级", "四级", "练习"],
                    "C": ["全部", "一级", "二级", "三级", "四级", "五级", "六级", "七级", "八级", "九级", "十级", "练习", "其他"],
                    "Python": ["全部", "一级", "二级", "三级", "四级", "五级", "六级", "练习"],
                    "ROBOT": ["全部", "一级", "二级", "三级", "四级", "五级", "六级", "七级", "八级"],
                    "NOI": ["全部"],
                    "NOIP": ["全部", "普及组", "提高组", "其他"],
                    "ZXXL": ["全部"],
                    "CSP-J": ["全部"],
                    "CSP-S": ["全部"],
                    "SY": ["全部"],
                    "SW": ["全部"],
                    "SF": ["全部"],
                    "LANQIAO_Scratch": ["全部", "初级", "中级", "其他"],
                    "LANQIAO_C": ["全部", "一级", "二级", "三级", "其他"],
                    "LANQIAO_Python": ["全部", "初级", "中级", "高级", "其他"],
                    "SCHOOL": ["全部"],
                    "其他": ["全部"]
                },
                selectedQuestionType: null,
                questionType: [{
                    value: 1000,
                    label: '全部'
                }, {
                    value: 0,
                    label: '单选题'
                }, {
                    value: 1,
                    label: '判断题'
                }, {
                    value: 4,
                    label: '编程题'
                }, {
                    value: 2,
                    label: '多选题'
                }, {
                    value: 3,
                    label: '简答题'
                }, {
                    value: 5,
                    label: '填空题'
                }, {
                    value: 99,
                    label: '其他'
                }],
            }
        },
        methods: {
            resetSelect() {
                this.selectedMatch = "ALL";
                this.selectedMatchIndex = 0;
                this.selectedLang = 'ALL';
                this.selectedLangIndex = 0;
                this.selectedLevel = 'ALL';
                this.selectedLevelIndex = 0;
                this.paperName = '';
                this.paperMonth = null;
                this.submitDate = null;
                this.selectedQuestionType = null;
                this.$emit('reset-select');
            },
            handleInput() {
                this.$emit('new-select-context', this.selectContext);
            },
            selectMonth() {
                this.$emit('new-select-paper-month', this.paperMonth);
            },
            selectByQuestionType(){
                // 选择题型
                this.$emit('new-select-question-type', this.selectedQuestionType);
            },
            selectDate() {
                this.$emit('new-select-submit-date', this.submitDate);
            },
            selectMatch(option, index) {
                this.selectedMatch = option;
                this.selectedMatchIndex = index;

                if (option === "NOI") {
                    this.selectedLang = 'ALL';
                    this.selectedLangIndex = 0;
                    this.selectedLevel = 'ALL';
                    this.selectedLevelIndex = 0;
                } else {
                    this.selectedLang = 'ALL';
                    this.selectedLangIndex = 0;
                    this.selectedLevel = 'ALL';
                    this.selectedLevelIndex = 0;
                }
                this.$emit('send-left-title-match', this.selectedMatchIndex);

                this.sendSelectedToParent();

            },
            selectLang(option, index) {
                this.selectedLang = option;
                this.selectedLangIndex = index;
                this.sendSelectedToParent();
            },
            selectLevel(option, index) {
                this.selectedLevel = option;
                this.selectedLevelIndex = index;
                this.sendSelectedToParent();
            },
            selectPaperType(paperType) {
                //paperType 0:免费 1：会员
                if (paperType === 1) {
                    this.isActivePaperTypeVip = !this.isActivePaperTypeVip;
                    this.isActivePaperTypeFree = false;
                    if (this.isActivePaperTypeVip) {
                        this.vipAnswer = 1;
                    } else {
                        this.vipAnswer = null;
                    }
                } else {
                    this.isActivePaperTypeFree = !this.isActivePaperTypeFree;
                    this.isActivePaperTypeVip = false;
                    if (this.isActivePaperTypeFree) {
                        this.vipAnswer = 0;
                    } else {
                        this.vipAnswer = null;
                    }
                }
                this.$emit('select-paper-type', this.vipAnswer);
            },
            //选择分类，同步父级
            sendSelectedToParent() {
                const data = {
                    selectedMatch: this.selectedMatch,
                    selectedLang: this.selectedLang,
                    selectedLevel: this.selectedLevel
                };
                this.$emit('send-selected-to-parent', data);
            }

        },

        watch: {
            matchAndLang: {
                handler(object) {
                    if (object != null) {
                        if (object.matchIndex != null && object.matchIndex !== '') {
                            this.selectedMatchIndex = object.matchIndex;
                            this.selectedMatch = object.matchName;
                        }

                        if (object.langIndex != null && object.langIndex !== '') {
                            this.selectedLang = object.langName;
                            this.selectedLangIndex = object.langIndex;
                        }

                        if (object.levelIndex != null && object.levelIndex !== '' && object.levelIndex !== 'undefine') {
                            this.selectedLevel = object.levelName;
                            this.selectedLevelIndex = object.levelIndex;
                        }
                        this.sendSelectedToParent();
                    }
                },
                deep: true
            },
            selectPaperNameByHome: {
                handler: function (n, o) {
                    this.selectContext = n

                }
            }
        },
        computed: {
            level2Options() {
                return this.selectedMatch ? this.langOptionsMap[this.selectedMatch] : [];
                // return this.selectedMatch ? this.langOptionsMap[this.selectedMatch] : [];
            },
            level3Options() {
                return this.selectedLang ? this.levelOptionsMap[this.selectedLang] : [];
            }
        },
        mounted() {
            // 滚动条的获取
            window.addEventListener('scroll', this.handleScrollx, true);
        }
    }
</script>

<style lang="scss" scoped>

    .custom-date-picker {
        width: 148px;
        height: 30px; /* 调整高度 */
        line-height: 30px; /* 行高与高度一致 */
        ::v-deep .el-input__inner {
            height: 30px;
            line-height: 30px;
        }

        ::v-deep .el-input__prefix,
        ::v-deep .el-input__icon {
            line-height: 30px; // 可以通过设置行高改变图标在竖直方向上位置

        }
    }


    .paper-select {
        width: 330px;
        margin-left: 10px;
        height: 30px;
        line-height: 30px;

        ::v-deep .el-input__inner {
            height: 30px;
            line-height: 30px;
        }

         ::v-deep .el-input__prefix,
         ::v-deep .el-input__icon {
            line-height: 30px; // 可以通过设置行高改变图标在竖直方向上位置

        }
    }

    .select {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 10px;
        box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);

        .select-content {
            padding: 24px 15px 6px;

        }


        .level {
            position: relative;
            padding-bottom: 15px;
            line-height: 26px;
            height: 26px;


            .level-name {
                font-size: 14px;
                float: left;
                color: #80848B;
                margin-left: 10px;
                white-space: pre;

            }

            .level-content {
                float: left;

                .level-sub-content {
                    max-width: 900px;
                    float: none;
                    height: 26px;
                    position: relative;

                    a {
                        text-decoration: none;
                        float: left;
                        height: 26px;
                        line-height: 26px;
                        font-size: 14px;
                        padding: 0 5px;
                        margin: 1px 5px;
                        color: #333;
                        cursor: pointer;
                        position: relative;

                        &:hover {
                            color: #0080FF;
                            background: #E7F3FF;
                            border-radius: 4px;
                        }

                    }

                    .customSelection2 {
                        color: #0080FF;
                        background: #E7F3FF;
                        border-radius: 4px;
                    }
                }
            }

            .customSelection {
                color: #0080FF;
                background: #E7F3FF;
                border-radius: 4px;

                .level-sub-content a {
                    color: #0080FF;
                }

            }

        }
    }

</style>
