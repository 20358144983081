<template>
    <div class="page">
        <div class="page-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>题库搜题</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="list-body clearfix">
            <leftSelect @select-match-lang="selectMatchAndLang" :leftMathIndex="leftMathIndex"></leftSelect>
            <div class="body-main">
                <mainSelect :matchAndLang="matchAndLangData"
                            :selectPaperNameByHome="content"
                            @send-selected-to-parent="getQuestionListBySelect"
                            @new-select-question-type="selectByQuestionType"
                            @new-select-paper-month="SelectQuestionByMonth"
                            @reset-select="resetSelectQuestionList"
                            @send-left-title-match="toLeftMathIndex"
                            @new-select-context="selectByQuestionTitle"></mainSelect>
                <el-table
                        v-loading="loading"
                        :data="tableData"
                        style="width: 100%; border-radius: 4px;box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);"
                        :row-class-name="tableRowClassName">
                    <el-table-column
                            prop="id"
                            label="序号"
                            width="70">
                    </el-table-column>
                    <el-table-column
                            prop="shortTitle"
                            label="题目"
                            :show-overflow-tooltip="true"
                            width="600px">
                        <template slot-scope="scope">
                            <div v-html="scope.row.shortTitle" ></div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="type"
                            style="width: 25%;"
                            label="标签"
                    >
                        <template slot-scope="scope">

                            <el-tag el-tag class="tag" size="small" type="success"
                                    v-if="scope.row.langCode ==='Scratch'">
                                Scratch
                            </el-tag>
                            <el-tag el-tag class="tag" size="small" type="success"
                                    v-else-if="scope.row.langCode ==='C'"> C/C++
                            </el-tag>
                            <el-tag el-tag class="tag" size="small" type="success"
                                    v-else-if="scope.row.langCode ==='Python'">
                                Python
                            </el-tag>
                            <!--                            <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.langCode ===3">-->
                            <!--                                NOIP-->
                            <!--                            </el-tag>-->
                            <el-tag el-tag class="tag" size="small" type="success"
                                    v-else-if="scope.row.langCode ==='ROBOT'">
                                机器人技术
                            </el-tag>
                            <!--                            <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.langCode ===5">-->
                            <!--                                科技素养/计算思维-->
                            <!--                            </el-tag>-->
                            <!--                            <el-tag el-tag class="tag" size="small" type="success" v-if="scope.row.langCode ===6">-->
                            <!--                                无人机技术-->
                            <!--                            </el-tag>-->
                            <el-tag el-tag class="tag" size="small" type="success" v-else>其他</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="questionType"
                            style="width: 5%"
                            label="题型"
                            filter-placement="bottom-end">
                        <template slot-scope="scope">
                            <el-tag class="tag" size="small" type="success" v-if="scope.row.questionType ===0">
                                单选题
                            </el-tag>
                            <el-tag class="tag" size="small" type="danger" v-if="scope.row.questionType ===1">
                                判断题
                            </el-tag>
                            <el-tag class="tag" size="small" type="info" v-if="scope.row.questionType ===2">
                                多选题
                            </el-tag>
                            <el-tag class="tag" size="small" type="success" v-if="scope.row.questionType ===3">
                                简答题
                            </el-tag>
                            <el-tag class="tag" size="small" type="warning" v-if="scope.row.questionType ===4">
                                编程题
                            </el-tag>
                            <el-tag size="small" type="success" v-if="scope.row.questionType ===5">填空题</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="操作"
                            style="width: 10%">
                        <template slot-scope="scope">
                            <el-button
                                    @click.native.prevent="gotoQuestionDetail(scope.row.id)"
                                    type="text"
                                    size="small">
                                开始答题
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-empty v-if="tableData.length === 0" style="background-color: #fff"></el-empty>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total"
                               style="margin-top: 20px;text-align: center">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import questionDetail from "./components/questionDetail.vue"
    import leftSelect from "@/components/leftSelect.vue"
    import mainSelect from "@/components/mainSelect.vue"

    import {
        getQuestionDetail,
        userAnswerList,
        countByDaySubmit,
        questionList
    } from "@/api/index.js"

    export default {
        components: {
            leftSelect,
            mainSelect,
            questionDetail
        },
        data() {
            return {
                maodian: '#1',
                // allNumberArr: [],
                answerArr: [],
                questionDetail: {},
                flag: true,
                flag2: false,
                flag22: false,
                flag3: false,
                flag4: false,
                content: null,
                activeName: 'first',
                tableData: [],
                loading: false,
                allInfo: {
                    total: 0
                },
                nowType: {},
                currentPage: 1,
                pageSize: 10,
                haveMore: false,
                noMore: false,
                isLoading: true,
                matchAndLangData: {},
                paperName: '',
                matchParent: 'ALL',
                langParent: 'ALL',
                levelParent: 'ALL',
                vipAnswer: null,
                sortFlag: null,
                paperMonth: null,
                questionType: null,
                leftMathIndex: 0
            };
        },
        watch: {
            content: {
                handler: function (n, o) {
                    if (n !== undefined) {
                        this.getQuestionList();
                    }
                }
            },
            vipAnswer: {
                handler: function (n, o) {
                    this.getQuestionList();
                }
            },
        },
        mounted() {
            this.getQuestionList()
        },
        methods: {
            selectMatchAndLang(data) {
                this.matchAndLangData = data;
            },
            getQuestionListBySelect(data) {
                this.matchParent = data.selectedMatch;
                this.langParent = data.selectedLang;
                this.levelParent = data.selectedLevel;

                this.getQuestionList();
            },
            SelectPaperByMonth(val) {
                this.sortFlag = val;
                this.getQuestionList();
            },
            selectByQuestionType(val) {
                this.questionType = val;
                this.getQuestionList();
            },
            selectByQuestionTitle(val) {
                this.content = val
            },
            SelectQuestionByMonth(val) {
                this.sortFlag = val;
                this.getQuestionList();
            },
            toLeftMathIndex(val) {
                this.leftMathIndex = val;
            },
            isAnswerArr(item) {
                this.answerArr = item
            },
            resetData() {
                this.loading = false,
                    this.examinationRecordArray = [],
                    this.allInfo = {
                        total: 0
                    },
                    this.currentPage = 1,
                    this.pageSize = 10,
                    this.haveMore = false,
                    this.noMore = false,
                    this.isLoading = true
            },
            changeSel(val) {
                this.type = val.type
                this.getQuestionList()
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.currentPage = 1;
                this.getQuestionList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getQuestionList()
            },
            resetSelectQuestionList() {
                this.matchParent = 'ALL';
                this.langParent = 'ALL';
                this.levelParent = 'ALL';
                this.content = "";
                this.vipAnswer = null;
                this.sortFlag = null;
                this.getQuestionList();
            },
            //题目列表
            getQuestionList() {
                this.loading = true
                let param = {
                    content: this.content,
                    match: this.matchParent,
                    lang: this.langParent,
                    level: this.levelParent,
                    vipAnswer: this.vipAnswer,
                    sortFlag: this.sortFlag,
                    questionType: this.questionType,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                };
                questionList(param).then(res => {
                    if (res.code === "0000") {
                        this.allInfo = res.data;
                        this.tableData = res.data.list;
                        this.loading = false;
                    }
                }).catch(() => {
                    this.$router.push({
                        path: '/'
                    })
                });
            },
            getQuestionInfo(id) {
                this.loading = true
                getQuestionDetail(id).then(res => {
                    if (res.code === "0000") {
                        this.questionDetail = res.data
                        let numberArr = []
                        // res.data.titleItems.forEach(item => {
                        //   if (item.questionItems) {
                        //     item.questionItems.forEach(item1 => {
                        //       numberArr.push(item1.itemOrder)
                        //     })
                        //   }
                        // })
                        this.allNumberArr = numberArr
                        this.loading = false
                    }
                }).catch(() => {
                    this.loading = false
                });
            },
            gotoQuestionDetail(id) {
                countByDaySubmit().then(res => {
                    if (res.code === "0000") {

                        let routeData = this.$router.resolve({
                            path: "/questionDetail?id=" + id
                        });
                        window.open(routeData.href, '_blank');
                    }
                }).catch(() => {
                    // this.allInfo = res.data
                    // this.tableData = res.data.list
                    // this.loading = false
                    this.getQuestionList()
                });

            },
            // 题库答题记录
            // userAnswerList() {
            //     this.loading = true
            //     let param = {
            //         content: this.content,
            //         currentPage: this.currentPage,
            //         pageSize: this.pageSize,
            //         type: Number(this.type),
            //         // level: Number(this.nowType.level),
            //     }
            //     userAnswerList().then(res => {
            //         if (res.code === "0000") {
            //             this.tableData2=[]
            //             this.tableData2 = res.data
            //             console.log(this.tableData2)
            //             this.loading = false
            //         }
            //     }).catch(() => {
            //         this.$router.push({
            //             path: '/'
            //         })
            //     });
            // },
            tableRowClassName({row, rowIndex}) {
                if (rowIndex === 1) {
                    return 'warning-row';
                } else if (rowIndex === 3) {
                    return 'success-row';
                }
                return '';
            },

        }
    };
</script>

<style>
    /*溢出隐藏*/
    .el-tooltip__popper {
        max-width: 28%;
    }
</style>
<style lang="scss" scoped>

    .page-breadcrumb {
        margin: 15px 0;
        cursor: pointer;
    }

    .el-divider__text {
        background-color: #f5f5f5;
        color: #808080;
    }

    .list-body {
        width: 1200px;
        margin: 0 auto;
        min-height: 600px;
    }

    .body-main {
        margin-left: 275px;
        /*flex: 1;*/
        display: block;
        max-width: 77%;
        /*min-height: 100vh;*/
        box-sizing: border-box;
        padding-bottom: 30px;
    }
</style>
