<template>
    <div :class="{ fed: isFixed,'body-left':true}" ref="leftDiv">
        <!--        <div>-->
        <!--            <span>真题模拟</span>-->
        <!--            <el-divider direction="vertical"></el-divider>-->
        <!--            <span>专项训练</span>-->
        <!--        </div>-->
        <el-col>
            <el-menu :default-active="activeIndex" class="el-menu-vertical-demo">
                <el-menu-item index="0" @click="selectMatchAndLang('ALL',0,'ALL',0)">
                    <i class="el-icon-menu"></i>
                    <span slot="title">全部</span>
                </el-menu-item>
                <el-menu-item index="1" @click="selectMatchAndLang('QCEIT',1,'ALL',0)">
                    <span slot="title">中国电子学会</span>
                </el-menu-item>
                <el-menu-item index="2" @click="selectMatchAndLang('CCF_GESP',2,'ALL',0)">
                    <span slot="title">GESP CCF编程能力等级认证</span>
                </el-menu-item>
                <el-menu-item index="3" @click="selectMatchAndLang('NOI',3,'ALL',0)">
                    <span slot="title">全国中学生信息学奥林匹克竞赛</span>
                </el-menu-item>
                <el-menu-item index="4" @click="selectMatchAndLang('LANQIAO',4,'ALL',0)">
                    <span slot="title">蓝桥杯</span>
                </el-menu-item>
                <el-menu-item index="5" @click="selectMatchAndLang('NOC',5,'ALL',0)">
                    <span slot="title">全国中小学信息技术创新与实践大赛</span>
                </el-menu-item>
                <el-menu-item index="6" @click="selectMatchAndLang('SYSW',6,'SY',1)">
                    <span slot="title">科技素养</span>
                </el-menu-item>
                <el-menu-item index="7" @click="selectMatchAndLang('SYSW',6,'SW',2)">
                    <span slot="title">计算思维</span>
                </el-menu-item>
                <el-menu-item index="8" @click="selectMatchAndLang('OTHER',8,'ALL',1)">
                    <span slot="title">算法竞赛</span>
                </el-menu-item>
                <el-menu-item index="9" @click="selectMatchAndLang('OTHER',8,'ALL',0)">
                    <span slot="title">其他</span>
                </el-menu-item>
            </el-menu>
        </el-col>
    </div>
</template>

<script>
    export default {
        name: "leftSelect",
        props: ['leftMathIndex'],
        data() {
            return {
                activeIndex: '0',
                isFixed: false,
            }
        },
        methods: {
            selectMatchAndLang(matchName, matchIndex, langName, langIndex) {
                const data = {
                    matchName: matchName,
                    matchIndex: matchIndex,
                    langName: langName,
                    langIndex: langIndex
                };
                this.$emit('select-match-lang', data);
            },
            handleScrollx() {
                // console.log('滚动高度', window.pageYOffset);
                const juli = window.pageYOffset;
                // console.log('距离顶部高度', this.$refs.leftDiv.getBoundingClientRect().top);
                if (juli >= 20) {
                    this.isFixed = true
                } else {
                    this.isFixed = false
                }
            },

        },
        watch: {
            leftMathIndex: {
                handler: function (n, o) {
                    if (n !== undefined) {
                        if (n === 7 || n === 8) {
                            n = 9
                        }
                        this.activeIndex = n.toString();
                    }
                }
            },
        },
        mounted() {
            // 滚动条的获取
            window.addEventListener('scroll', this.handleScrollx, true);
        }
    }
</script>

<style lang="scss" scoped>

    .body-left {
        float: left;
        width: 260px;
        height: 60.9vh;
        margin-right: 15px;
        box-sizing: border-box;
        border-radius: 4px;
        background: #fff;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);
    }

    .body-left.fed {
        position: fixed;
        top: 60px;
        z-index: 0;
        transition: opacity 0.3s;
    }

</style>
